$yellow: #fef800;
$blue: #004FC9;
$black: #000;
$white: #fff;

$tm-grau: #BFC5CE;
$tm-light: lighten($blue, 65%);
$tm-blue: $blue;
$tokheim-red: #DD291A;
$tokheim-red-light: lighten($tokheim-red, 50%);

$honig: #ffd53d;
$honig-light: lighten($honig, 50%);

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;


$grau: #878787;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #FF0000;
$orange: #fd7e14;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;


$primary: $honig;
$secondary: $honig-light;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $gray-800;
// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

$has-messages: $orange;

$role: $gray-400;

$colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
        "honig":$honig,
        "has-messages": $has-messages,
        "role":$role
);


$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        default: $grau,
        tmlight: $honig-light,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "honig":$honig,
        "has-messages": $has-messages,
        role: $role
);
